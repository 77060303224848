import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Table, TableRow, TableHead, TableBody, TableCell, TableContainer } from "@material-ui/core";
import CreatorSpaceRow from "./CreatorSpaceRow.js";
import SimpleBar from "simplebar-react";
import { db, setDoc, setDocSameUpdateTime, increment } from "../../firestore";
import CreateIcon from "@material-ui/icons/Create";
import Tags from "../Tags";
import { useOwnMolecules } from "../../hooks/molecules";

const columns = [
    { id: "title", label: "My Pages", width: "auto" },
    {
        id: "hint",
        label: "Hint",
        align: "right",
        width: "auto"
    },
    {
        id: "errorReports",
        label: "Errors",
        align: "right",
        width: "auto"
    },
    { id: "public", label: "Public", align: "center", width: "auto" },
    { id: "edit", label: "", align: "center", width: "20px" }
];

const MyPages = props => {
    const history = useHistory();
    const user = useSelector(state => state.login.user);
    const [tags, setTags] = useState([]);
    const [selected, setSelected] = useState([]);
    const [molecules, setMoleculesLocally] = useOwnMolecules();

    useEffect(() => {
        if (!molecules) return;
        const allTagsMap = {};
        molecules.forEach(m => (m.tags || []).forEach(t => (allTagsMap[t] = (allTagsMap[t] || 0) + 1)));
        const allTags = [];
        Object.keys(allTagsMap).forEach(key => allTags.push({ title: key, count: allTagsMap[key] }));
        setTags(allTags);
    }, [molecules]);

    if (!molecules) return <></>;

    return (
        <>
            <div style={{ display: "flex", padding: "10px" }}>
                <Button
                    onClick={async () => {
                        const docRef = db.collection("molecules").doc();
                        const molecule = {
                            id: docRef.id,
                            owner: user.id,
                            title: "",
                            public: false,
                            atoms: [],
                            random: []
                        };
                        await setDoc(docRef, molecule);
                        await db.collection("stats").doc("created").set({ count: increment }, { merge: true });
                        history.push("/MyPages/" + molecule.id);
                    }}
                    startIcon={<CreateIcon />}>
                    Create New Page
                </Button>
                <div style={{ flex: 1, marginLeft: "10px" }}>
                    <Tags tags={tags} onChange={setSelected} />
                </div>
            </div>
            <TableContainer>
                <SimpleBar style={{ height: "100%", overflowX: "hidden" }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column, i) => (
                                    <TableCell key={i} align={column.align} style={{ minWidth: column.minWidth }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {molecules.length === 0 ? (
                                <TableRow>
                                    <TableCell align="center" colSpan={columns.length}>
                                        <div
                                            style={{
                                                color: "lightgrey",
                                                fontSize: "1.3rem",
                                                backgroundColor: "transparent"
                                            }}>
                                            No pages created
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <>
                                    {molecules.map((mol, i) => {
                                        let show = true;
                                        selected.forEach(s => {
                                            if (!(mol.tags || []).includes(s)) show = false;
                                        });
                                        if (!show) return <></>;
                                        return (
                                            <CreatorSpaceRow
                                                molecule={molecules[i]}
                                                key={i}
                                                row={mol}
                                                columns={columns}
                                                editRow={() => history.push("/MyPages/" + mol.id)}
                                                update={async molecule => {
                                                    await setDocSameUpdateTime(
                                                        db.collection("molecules").doc(molecule.id),
                                                        molecule
                                                    );
                                                    setMoleculesLocally(mol =>
                                                        mol.map(m => (m.id === molecule.id ? molecule : m))
                                                    );
                                                }}
                                            />
                                        );
                                    })}
                                    <TableRow style={{ height: "100px", border: "none" }}>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </>
                            )}
                        </TableBody>
                    </Table>
                </SimpleBar>
            </TableContainer>
        </>
    );
};

export default MyPages;
