import React, { useState } from "react";
import { Container, TextField, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { db, setDoc } from "../firestore";
import { login } from "../actions";

const Profile = props => {
    const user = useSelector(state => state.login.user);
    const dispatch = useDispatch();
    const [name, setName] = useState(user.name);
    const [errorReportEmail, setErrorReportEmail] = useState(user.errorReportEmail ?? "");
    return (
        <Container maxWidth="md">
            <TextField label="Name" value={name} onChange={e => setName(e.target.value)} />
            <br />
            <br />
            <TextField
                label="Error report email"
                helperText="Send error reports to this email"
                value={errorReportEmail}
                onChange={e => setErrorReportEmail(e.target.value)}
            />
            <br />
            <br />
            <Button
                variant="contained"
                onClick={async () => {
                    const userData = Object.assign(user, { name, errorReportEmail });
                    await setDoc(db.collection("users").doc(user.id), userData);
                    dispatch(login({ user: userData }));
                }}>
                Save
            </Button>
        </Container>
    );
};

export default Profile;
